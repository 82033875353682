<div class="banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-text text">
                    <h1>Find Your <span>Desired</span> Job</h1>
                    <p>Connecting Worldwide Talents and Businesses</p>

                    <div class="banner-form-area">
                        <form>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label
                                            ><i  class="icofont-search-1" style="font-size: 20px;"></i
                                        ></label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Enter Keywords"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            style="border: 2px solid #cacacd; border-radius: 4px; padding-left: 10px"
                                            [(ngModel)]="jobTitle"
                                        />
                                    </div>
                                </div>

                                <!-- <div class="col-lg-4">
                                    <div class="form-group">
                                        <label
                                            ><i class="icofont-location-pin"></i
                                        ></label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="City or State"
                                            [(ngModel)]="jobLocation"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                        />
                                    </div>
                                </div> -->
                                <!-- <div class="col-lg-4">
                                    <div class="form-group">
                                        <label
                                            ><i class="icofont-location-pin"></i
                                        ></label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="City or State"
                                            [(ngModel)]="searchQuery"
                                            (ngModelChange)="onSearch($event)"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                        />
                                    </div> -->

                                    <!-- Dropdown for showing states -->
                                    <!-- <ul
                                        *ngIf="filteredStates.length > 0"
                                        class="dropdown-menu show"
                                    >
                                        <li
                                            *ngFor="let state of filteredStates"
                                            (click)="selectState(state)"
                                        >
                                            {{ state.name }}
                                        </li>
                                    </ul>
                                </div> -->


                                <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="d-block"><i class="icofont-location-pin"></i></label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Search city or state"
                                        [(ngModel)]="searchQuery"
                                        (ngModelChange)="onSearchLocation($event)"
                                        [ngModelOptions]="{ standalone: true }"
                                        style="border: 2px solid #cacacd; border-radius: 4px; padding-left: 10px"
                                    />
                                </div>
                                
                                <!-- Dropdown for showing cities and states -->
                                <ul *ngIf="filteredLocations.length > 0" class="dropdown-menu show unordered-list">
                                    <li
                                        *ngFor="let location of filteredLocations"
                                        (click)="selectLocation(location)"
                                    >
                                        {{ location.name }} <small>({{ location.type }})</small>
                                    </li>
                                </ul>
                            </div>

                            <!-- <div class="form-group">
                                <label class="d-block"><i class="icofont-location-pin"></i> Search city or state</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="autocomplete"
                                  placeholder="Search city or state"
                                  style="border: 2px solid #cacacd; border-radius: 4px; padding-left: 10px"
                                />
                              </div> -->
                              

                                <div class="col-lg-6">
                                    <div class="form-group mt-2">
                                        <ngx-select-dropdown
                                            [config]="config"
                                            [options]="jobCategory"
                                            [multiple]="true"
                                            [(ngModel)]="selectedCategory"
                                            [ngModelOptions]="{standalone: true}"
                                            (change)="onSelectCategory($event)"
                                        ></ngx-select-dropdown>
                                    </div>
                                </div>

                                <!-- <div class="col-lg-4">
                                    <div class="form-group">
                                        <label
                                            ><i class="fa fa-briefcase"></i
                                        ></label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="2 years"
                                            [(ngModel)]="jobExperience"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                        />
                                    </div>
                                </div> -->

                                <!-- <div class="col-lg-4">
                                    <div class="form-group mt-2">
                                        <label><i class="fa fa-usd"></i></label>
                                        <ngx-select-dropdown
                                            [config]="jobSalaryConfig"
                                            [options]="jobSalary"
                                            (change)="
                                                onSelectSalaryRange($event)
                                            "
                                        >
                                        </ngx-select-dropdown>
                                
                                    </div>
                                </div> -->

                                <div class="col-lg-6">
                                    <div class="form-group mt-2">
                                        <label>Job Type</label>
                                        <ngx-select-dropdown
                                            [config]="jobTypeConfig"
                                            [options]="jobTypes"
                                            (change)="onSelectJobType($event)"
                                        >
                                        </ngx-select-dropdown>
                                    </div>
                                </div>
                            </div>

                            <button
                                type="submit"
                                (click)="jobInfoListing()"
                                class="btn banner-form-btn mt-3 me-2"
                            >
                                Search
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="container back-link">
    <a routerLink="/home" class="cursor_pointer h5"><i class="fa fa-angle-double-left me-2"></i>Back</a>
</div> -->
<section class="job-area job-area-two pt-100">
    <div class="container">

        <div class="row mt-4">
            <ng-container *ngIf="jobInfo?.length > 0; else noJobFound">
            <div class="col-lg-5">
                <div class="job-item job-left-card" [class.active]="jobInfoDetails === job"  (click)="jobInfoData(job)" *ngFor="let job of jobInfo">
                    <!-- <img
                        [src]="job?.companyLogoDetails?.imageUrl"
                        class="job_image"
                        alt="Job"
                    /> -->
                    <div class="job-inner align-items-center">
                        <!-- <img src="../../../assets/img/home-1/icon.jpeg"  class="job_image" alt=""> -->
                        <div class="job-inner-left">
                            <h3>{{ job?.jobTitle }}</h3>

                            <!-- <a [href]="job?.website" target="_blank">{{
                                job?.website
                            }}</a> -->
                            <ul class="unordered-list">
                                <li>
                                    <i class="icofont-location-pin"></i
                                    >{{ job?.jobLocation }}
                                </li>
                                <li>
                                    <i class="far fa-building"></i
                                    >{{ job?.jobCategory }} ({{
                                        job?.jobTitle
                                    }})
                                </li>
                                <li>
                                    <i class="fa fa-clock-o"></i
                                    >{{ job?.jobType }}
                                </li>

                                <!-- <ng-container>
                                    <div
                                        *ngIf="
                                            job.jobType === 'Hourly';
                                            else displayWithK
                                        "
                                    >
                                        <li>
                                            <i class="fa fa-money"></i>
                                            ${{ job?.salaryRange }}
                                        </li>
                                    </div>
                                    <ng-template #displayWithK>
                                        <li>
                                            <i class="fa fa-money"></i>
                                            {{ job?.salaryRange }}k
                                        </li>
                                    </ng-template>
                                </ng-container> -->

                                <!-- <li>
                                    <i class="fa fa-money"></i
                                    >{{ job?.salaryRange }}
                                </li> -->
                            </ul>

                            <div class="mt-3 custom-job-description">
                                <span class="fw-bold">Job Requirement:</span>
                                <div class="description" [innerHTML]="getFormattedJobRequirements(job)">
                                </div>
                            </div>

                            <!-- <div class="mt-3 custom-job-description">
                                <span class="fw-bold">Job Description:</span>
                                <div class="description" [innerHTML]="job?.jobDescription">
                                </div>
                            </div> -->

                        </div>
                        <div class="job-inner-right">
                            <ul class="unordered-list">
                                <!-- <li>
                                    <a
                                        [routerLink]="[
                                            '/candidate/job-detail',
                                            job?._id
                                        ]"
                                        >Apply</a
                                    >
                                </li> -->

                                <!-- <li>
                                    <span>{{ job?.jobType }}</span>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>


            <div *ngIf="jobInfo?.length > 0" class="col-lg-7  container-lg" >
                <div class="job-item job-card-right"  *ngIf="jobDetailsSelected" >
                    <div class="job-inner job-right-inner align-items-center mt-3">
                        <div class="job-inner-left">
                            <img src="../../../assets/img/home-1/icon.jpeg"  class="job_image" alt="">
                            <h3>{{ jobInfoDetails?.jobTitle }}</h3>

                            <!-- <a [href]="job?.website" target="_blank">{{
                                job?.website
                            }}</a> -->
                            <ul class="unordered-list">
                                <li>
                                    <i class="icofont-location-pin"></i
                                    >{{ jobInfoDetails?.jobLocation }}
                                </li>
                                <li>
                                    <i class="far fa-building"></i
                                    >{{ jobInfoDetails?.jobCategory }} ({{
                                        jobInfoDetails?.jobTitle
                                    }})
                                </li>
                                <li>
                                    <i class="fa fa-clock-o"></i
                                    >{{ jobInfoDetails?.jobType }}
                                </li>

                                <!-- <ng-container>
                                    <div
                                        *ngIf="
                                            job.jobType === 'Hourly';
                                            else displayWithK
                                        "
                                    >
                                        <li>
                                            <i class="fa fa-money"></i>
                                            ${{ job?.salaryRange }}
                                        </li>
                                    </div>
                                    <ng-template #displayWithK>
                                        <li>
                                            <i class="fa fa-money"></i>
                                            {{ job?.salaryRange }}k
                                        </li>
                                    </ng-template>
                                </ng-container> -->

                                <!-- <li>
                                    <i class="fa fa-money"></i
                                    >{{ job?.salaryRange }}
                                </li> -->
                            </ul>

                            <div class="mt-3 custom-job-description">
                                <span class="fw-bold">Job Requirement:</span>
                                <div class="description" [innerHTML]="jobInfoDetails?.jobRequirements">
                                </div>
                            </div>

                            <div class="mt-3 custom-job-description">
                                <span class="fw-bold">Job Description:</span>
                                <div class="description" [innerHTML]="jobInfoDetails?.jobDescription">
                                </div>
                            </div>

                        </div>
                        <div class="job-inner-right">
                            <ul class="unordered-list">
                                <li>
                                    <a
                                        [routerLink]="[
                                            '/candidate/job-detail',
                                            jobInfoDetails?._id
                                        ]"
                                        >Apply</a
                                    >
                                </li>
                                <li *ngIf="userData?.role === 'candidate'">
                                    <a style="cursor: pointer;"
                                        (click)="saveJobs()"
                                        >
                                        <small
                                        class="loadingspinner d-inline-block ms-2"
                                        *ngIf="loader"
                                        ></small>
                                        Save</a
                                    >
                                </li>

                                <!-- <li>
                                    <span>{{ job?.jobType }}</span>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                    
                </div>

                <div class="job-item job-card-right empty-job-ui" *ngIf="!jobDetailsSelected">
                    <h3><i class="fa fa-arrow-left " style="font-size:24px"></i> Select a job</h3>
                    <p>Display details here</p>
                    <img src="../../../assets/img/home-1/icon.jpeg" class="job_image-empty" alt="">
                </div>
            </div>

            <nav *ngIf="jobInfo?.length > 0" aria-label="Page navigation example pagination">
                <ul class="pagination unordered-list">
                    <li class="page-item" [class.disabled]="currentPage === 1">
                        <a
                            class="page-link cursor_pointer"
                            (click)="onPageChange(currentPage - 1)"
                            aria-label="Previous"
                        >
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li
                        class="page-item"
                        *ngFor="
                            let page of [].constructor(totalPages);
                            let i = index
                        "
                    >
                        <a
                            class="page-link cursor_pointer"
                            (click)="onPageChange(i + 1)"
                            >{{ i + 1 }}</a
                        >
                    </li>
                    <li
                        class="page-item"
                        [class.disabled]="currentPage === totalPages"
                    >
                        <a
                            class="page-link cursor_pointer"
                            (click)="onPageChange(currentPage + 1)"
                            aria-label="Next"
                        >
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>

            <div *ngIf="jobInfo?.length > 0" class="pagination-controls ">
                <span>Page {{ currentPage }} of {{ totalPages }}</span>
            </div>

            <ng-template #noJobFound>
                <div class="text-center mb-5">
                <h5>Job Information Not Found</h5>
                </div>
              </ng-template>

        </div>
    </div>
</section>




