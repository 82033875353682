<footer>
    <div class="container">
        <div class="subscribe-area">
            <div class="section-title">
                <h2>Subscribe Newsletter</h2>
            </div>

            <!-- <div class="subscribe-shape">
                <img src="assets/img/shape/1.png" alt="Shape">
                <img src="assets/img/shape/1.png" alt="Shape">
                <img src="assets/img/shape/1.png" alt="Shape">
                <img src="assets/img/shape/1.png" alt="Shape">
                <img src="assets/img/shape/2.png" alt="Shape">
            </div> -->

            <div class="subscribe-item">
                <form class="newsletter-form">
                    <input type="email" class="form-control" placeholder="Enter Your Email" name="EMAIL" required autocomplete="off">
                    <button class="btn subscribe-btn" type="submit">Subscribe</button>
                </form>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="../../../../assets/img/home-1/logo.png" class="footer_logo" alt="Logo"></a>
                        <p>Australia’s Leading Recruitment Company.</p>
                        <ul>
                            <!-- <li><a href="#" target="_blank"><i class="icofont-youtube-play"></i></a></li> -->
                            <li><a href="https://www.facebook.com/magnusrecruitmentaus/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.linkedin.com/in/magnus-recruitment-solutions-13895b198/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="https://www.instagram.com/magnusrecruitmentsolutions" target="_blank"><i class="fa fa-instagram"></i></a></li>
                            <li><a href="https://www.youtube.com/@MagnusRecruitmentSolutions" target="_blank"><i class="fa fa-youtube-play"></i></a></li>

                            <!-- <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-category">
                        <h3>Category</h3>

                        <ul>
                            <li><a [routerLink]="[isAdmin ? '/admin/job-list' : '/job-list']" [queryParams]="{ category: 'Education' }"><i class="icofont-simple-right"></i> Education</a></li>
                            <li><a [routerLink]="[isAdmin ? '/admin/job-list' : '/job-list']" [queryParams]="{ category: 'Health' }"><i class="icofont-simple-right"></i> Health</a></li>
                            <li><a [routerLink]="[isAdmin ? '/admin/job-list' : '/job-list']" [queryParams]="{ category: 'Hospitality' }"><i class="icofont-simple-right"></i> Hospitality</a></li>
                            <li><a [routerLink]="[isAdmin ? '/admin/job-list' : '/job-list']" [queryParams]="{ category: 'Trade' }"><i class="icofont-simple-right"></i> Trade</a></li>
                            <li><a [routerLink]="[isAdmin ? '/admin/job-list' : '/job-list']" [queryParams]="{ category: 'Manufacturing' }"><i class="icofont-simple-right"></i> Manufacturing</a></li>
                            <li><a [routerLink]="[isAdmin ? '/admin/job-list' : '/job-list']" [queryParams]="{ category: 'Mining' }"><i class="icofont-simple-right"></i> Mining</a></li>
                            <li><a [routerLink]="[isAdmin ? '/admin/job-list' : '/job-list']" [queryParams]="{ category: 'Engineering' }"><i class="icofont-simple-right"></i> Engineering</a></li>
                            <li><a [routerLink]="[isAdmin ? '/admin/job-list' : '/job-list']" [queryParams]="{ category: 'Information Technology (IT)' }"><i class="icofont-simple-right"></i> Information Technology (IT)</a></li>
                            <li><a [routerLink]="[isAdmin ? '/admin/job-list' : '/job-list']" [queryParams]="{ category: 'Childcare' }"><i class="icofont-simple-right"></i> Childcare</a></li>
                            <li><a [routerLink]="[isAdmin ? '/admin/job-list' : '/job-list']" [queryParams]="{ category: 'Horticulture' }"><i class="icofont-simple-right"></i> Horticulture</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-category">
                        <h3>Quick Links</h3>

                        <ul>
                            <li><a routerLink="/"><i class="icofont-simple-right"></i> Home</a></li>
                            <li><a routerLink="/about"><i class="icofont-simple-right"></i> About Us</a></li>
                            <li><a routerLink="/blog"><i class="icofont-simple-right"></i> Blogs</a></li>
                            <!-- <li><a routerLink="/company-list"><i class="icofont-simple-right"></i> Companies</a></li> -->
                            <li><a routerLink="/"><i class="icofont-simple-right"></i> Testimonials</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-find">
                        <h3>Find Us</h3>

                        <ul>
                            <li><i class="icofont-ui-call"></i><a href="tel:+61480199145">+61 480 199 145</a></li>
                            <li><i class="icofont-ui-call"></i><a href="tel:+61485981356">+61 485 981 356</a></li>
                            <li><i class="icofont-ui-call"></i><a href="tel:+61483848525">+61 483 848 525</a></li>
                            <li><i class="icofont-ui-call"></i><a href="tel:+611800960992">+61 1800 960 992</a></li>                            
                            <li><i class="icofont-ui-email"></i> 
                                <a href="mailto:info@magnusrecruitment.com.au">info&#64;magnusrecruitment.com.au</a>
                            </li>
                            <li> <div
                                class="calendly-logo"
                                
                                (click)="openCalendly()"
                            >
                                <img
                                    src="../../../../assets/img/home-1/calendly.png"
                                    class="appointment rounded-circle"
                                />
                                <span class="booking">Book an appointment</span>
                            </div></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row">
                <div class="col-lg-6">
                    <div class="copyright-item">
                        <a  target="_blank">
                            <p class="text-white">&#64;{{ currentYear }} Magnus All Rights Reserved</p>
                        </a>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="copyright-item copyright-right">
                        <ul>
                            <li><a  target="_blank">Terms & Conditions</a></li>
                            <li><span>-</span></li>
                            <li><a  target="_blank">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>