import { Component, Query } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { City, State } from "country-state-city";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { JobTitleService } from "src/app/shared/dummy-data/job-create.service";
import { ApplyJobService } from "src/app/shared/services/apply-job.service";
import { JobCreateService } from "src/app/shared/services/job-create.service";
import { AuthService } from "src/app/store/auth/auth.service";
declare var google: any;
@Component({
    selector: "app-job-list",
    templateUrl: "./job-list.component.html",
    styleUrl: "./job-list.component.scss",
})
export class JobListComponent {
    userO = this.authService.userO;
    private userSub: Subscription;
    jobInfo: any[] = [];
    jobDetails: any[] = [];
    jobRequirement: any = [];
    jobCategory: any[] = [];
    categoryArray: any = [];
    jobRequirements: [];
    category: any[] = [];
    jobType: any;
    jobTypes: any[] = [];
    jobSalary: any[] = [];
    jobHourlySalary: any[] = [];
    states: any[] = [];
    cities:any[]  = []
    filteredStates: any[] = [];
    images: any = {};
    currentPage: number = 1;
    totalItems: number = 0;
    totalItemsPerPage = 20;
    totalPages: number = 0;
    imageUrl: any;
    selectedCategory: any;
    jobTitle: string = "";
    jobLocation: string = "";
    jobExperience: string = "";
    salaryRange: string = "";
    searchQuery: string = "";
    public selectedState: string; 
    public searchQueryCity: string = '';
    locations: { name: any; type: string; }[];
    filteredLocations: any[];
    jobInfoDetails: any = {};
    jobDetailsSelected: boolean = false;
    loader: boolean = false;
    candidateEmail: any;
    userData: any = {};
    jobSearch: string = "";
    // public filteredCities: string[] = [];
    constructor(
        private readonly jobCreateService: JobCreateService,
        private readonly jobTitleService: JobTitleService,
        private readonly applyJobService:ApplyJobService,
        private route: ActivatedRoute,
        private readonly ngxToaster: ToastrService,
        private readonly authService: AuthService
    ) {}

    ngOnInit() {
        this.userSub = this.userO.subscribe((data) => {
            if (!data) return;
            if (data) {
              console.log(data)  
              this.userData = data?.body ?? data
              this.candidateEmail = data?.email ?? data?.body?.email; 
            }
          // this.getDocuments()
          });    
        this.route.queryParams.subscribe(params => {
            const job = params['job'] || '';
            const jobId = params['title']
            const category = params['category'];
            this.getJobCategories(category);
            // this.category.push(category)
            // this.selectedCategory = category;
            
            console.log('category',this.category)
            console.log('Selected Category:', category);
            this.jobSearch = jobId
            this.jobTitle = job
            // this.category.push(job);
        });
        this.jobInfoListing();
        this.getJobCategories();
        this.getJobTypes();
        this.getJobSalary();
        this.getJobHourlySalary();
        this.states = State.getStatesOfCountry("AU"); 
        this.cities = City.getCitiesOfCountry("AU");
    
        // Combine both states and cities into one array
        this.locations = [
            ...this.states.map((state) => ({ name: state.name, type: 'State' })),
            ...this.cities.map((city) => ({ name: city.name, type: 'City' })),
        ];
    
        this.filteredLocations = [];

        const input = document.getElementById('autocomplete') as HTMLInputElement;
        const autocomplete = new google.maps.places.Autocomplete(input);
        
        // Optional: Add a listener to get the selected place's details
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          if (place.geometry) {
            console.log('Selected Place: ', place);
          }
        });
    }

    //job category drop-down list
    getJobCategories(categoryFromUrl?: string) {
        this.applyJobService.getAllJobCategoriesForFilter()
            .subscribe((jobListing: any) => {
                console.log('bbbb',jobListing)
                this.jobCategory = jobListing.body.map((job: any) => ({
                    category: `${job.categoryName}  (${job.jobCount})`,
                    originalCategory: job.categoryName,
                    totalJobs: job.jobCount,
                }));
                  // Preselect category if it matches
                  if (categoryFromUrl) {
                 const matchedCategory = this.jobCategory.find(
                  cat => cat.originalCategory.toLowerCase() === categoryFromUrl.toLowerCase()
                  );
 
                   if (matchedCategory) {
                   this.selectedCategory = [matchedCategory]; // for ngModel binding
                   this.category = [matchedCategory.originalCategory]; // for your filter logic
                   this.jobInfoListing();
                }
             }
            });
    }


    formatJobDescription(rawHtml: string): string {
        return rawHtml
          .replace(/<p>\s*<\/p>/g, '')                       // Remove empty <p> tags
          .replace(/<br\s*\/?>\s*(<br\s*\/?>)+/g, '<br>')     // Replace multiple <br> with one
          .replace(/<\/p>\s*<p>/g, '</p><p>')                // Collapse spaces between paragraphs
          .replace(/\s{2,}/g, ' ')                           // Collapse multiple spaces
          .replace(/\n{2,}/g, '\n')                          // Collapse multiple newlines
          .trim();
      }

      


// Search for both cities and states
onSearchLocation(query: string): void {
    if (query) {
        this.jobLocation = query
        this.filteredLocations = this.locations.filter((location) =>
            location.name.toLowerCase().includes(query.toLowerCase()) ||
        location.name.toLowerCase() === this.jobLocation.toLowerCase()
         
        );
    } else {
        this.filteredLocations = [];
    }
}

jobInfoData(jobInfo:any){
    this.jobDetailsSelected = true
    this.jobInfoDetails = jobInfo
    this.jobInfoDetails.jobDescription = this.sanitizeJobDescription(this.jobInfoDetails.jobDescription);
}



// Handle selection of a location
selectLocation(location: any): void {
    this.searchQuery = location.name;
    this.jobLocation = this.searchQuery;
    this.jobInfoListing(); // Call your function to update job listings
    console.log(`${location.type} selected:`, this.searchQuery);
    this.filteredLocations = [];
}
    //job types drop-down list
    getJobTypes() {
        this.jobTypes = this.jobTitleService.getJobType();
    }

    //job salary
    getJobSalary() {
        this.jobSalary = this.jobTitleService.getJobSalary().map((job) => {
            let salaryRange = job.salary.split(" - ");

            return {
                ...job,
                salary: `${salaryRange[0]}k - ${salaryRange[1]}k`,
            };
        });
    }

    //job hourly salary
    getJobHourlySalary() {
        this.jobHourlySalary = this.jobTitleService
            .getHourlySalary()
            .map((job) => {
                let salaryRange = job.salary.split(" - ");

                return {
                    ...job,
                    salary: `$${salaryRange[0]} - $${salaryRange[1]}`,
                };
            });
    }

    //on select of job category
    onSelectCategory(selectedItems: any) {
        this.category = [];
        selectedItems.value.forEach((item: any) => {
            console.log(item.originalCategory);
            if (!this.category.includes(item.originalCategory)) {
                this.category.push(item.originalCategory);
            }
        });
        this.jobInfoListing();
    }

    //on select of job type
    onSelectJobType(selectedJobType: any) {
        this.jobType = selectedJobType?.value?.type;
        if (this.jobType === "Hourly") {
            this.jobSalary = this.jobHourlySalary;
        } else {
            this.getJobSalary();
        }
        this.jobInfoListing();
    }

    //on select salary Range
    onSelectSalaryRange(event: any) {
        this.salaryRange = event?.value?.salary;
        this.salaryRange = this.salaryRange.replace(/k|\$/g, "").trim();
        this.jobInfoListing();
    }

    //job listing
    jobInfoListing() {
        this.jobCreateService
            .getJobListing(
                this.currentPage,
                this.totalItemsPerPage,
                this.category,
                this.jobTitle,
                this.jobLocation,
                this.jobExperience,
                this.salaryRange,
                this.jobType,
                this.jobSearch
            )
            .subscribe((data: any) => {
                this.jobInfo = data?.body;
                if (this.jobInfo && this.jobInfo.length > 0) {
                    this.totalItems = data?.totalRecords;
                    this.totalPages = Math.ceil(
                        this.totalItems / this.totalItemsPerPage
                    );
                this.jobInfoDetails = this.jobInfo[0];
                this.jobInfoDetails.jobDescription = this.sanitizeJobDescription(this.jobInfoDetails.jobDescription);
                this.jobDetailsSelected = true;
                } else {
                    this.jobRequirement = [];
                }
            });
    }


    sanitizeJobDescription(description: string): string {
        return description
          .replace(/<p><br><\/p>/g, '')         // Remove empty line breaks
          .replace(/&nbsp;/g, '');              // Remove non-breaking spaces
    }

    payLoad() {
        return {
            jobIds: [this.jobInfoDetails?._id] 
        };
    }
    


saveJobs() {
    const userData = JSON.parse(localStorage.getItem('user-data') || '{}');
    console.log('userData' , userData)
    // Check if userData exists and if role is either admin or employee
    if (!userData || Object.keys(userData).length === 0) {
        this.ngxToaster.error('Please Login.');
        return;
      }
    
    //   // Check if userData exists and if role is either admin or employee
    //   if (userData?.body?.role && userData?.role  === 'admin' || userData?.body?.role && userData?.role === 'employee') {
    //     this.ngxToaster.error('Access Denied: Admin and Employee roles cannot hit this API.');
    //     return;
    //   }
  
    this.loader = true;
    this.jobCreateService.saveJobs(this.candidateEmail , this.payLoad()).subscribe(
      (res: any) => {
        if (res?.isData === true) {
          this.loader = false;
          this.ngxToaster.success(res?.result);
        } else {
          this.loader = false;
          this.ngxToaster.error(res?.result);
        }
      },
      (error) => {
        this.loader = false;
        this.ngxToaster.error('An error occurred while saving the job.');
      }
    );
  }  

    getFormattedJobRequirements(job: any): string {
        if (!job?.jobRequirements) return '';
      
        // Convert string to DOM
        const parser = new DOMParser();
        const doc = parser.parseFromString(job.jobRequirements, 'text/html');
      
        // Detect list type (ul or ol)
        const listElement = doc.querySelector('ul') || doc.querySelector('ol');
        if (!listElement) return job.jobRequirements; // If no list found, return original
      
        // Get all <li> elements
        const items = listElement.querySelectorAll('li');
      
        // If 3 or fewer items, return as is
        if (items.length <= 3) return job.jobRequirements;
      
        // Create a new list (same type as original)
        const newList = document.createElement(listElement.tagName.toLowerCase());
        for (let i = 0; i < 3; i++) {
          newList.appendChild(items[i].cloneNode(true));
        }
      
        // Clone the 3rd item and append "-----------------------"
        const thirdItemClone = items[2].cloneNode(true) as HTMLElement;
        thirdItemClone.innerHTML += ' <span style="white-space: nowrap;"></span>';
      
        // Replace the 3rd item with modified one
        newList.replaceChild(thirdItemClone, newList.lastChild!);
      
        return newList.outerHTML;
      }
      
      
      

    onPageChange(page: number) {
        if (page >= 1 && page <= this.totalPages) {
            this.currentPage = page;
            this.jobInfoListing();
            window.scrollTo(0, 0); 
        }
    }

    //ngx-selector-dropDown-Configuration for job category
    config = {
        displayKey: "category",
        search: true,
        height: "auto",
        placeholder: "Job Category",
        customComparator: () => {},
        moreText: "more",
        noResultsFound: "No results found!",
        searchPlaceholder: "Search",
        searchOnKey: "category",
        clearOnSelection: false,
        inputDirection: "ltr",
        multiple: true,
    };

    //ngx-selector-dropDown-Configuration for job type
    jobTypeConfig = {
        displayKey: "type",
        search: true,
        height: "auto",
        placeholder: "Select job Type",
        customComparator: () => {},
        moreText: "more",
        noResultsFound: "No results found!",
        searchPlaceholder: "Search",
        searchOnKey: "type",
        clearOnSelection: false,
        inputDirection: "ltr",
    };
    //ngx-selector-dropDown-Configuration (job salary)
    jobSalaryConfig = {
        displayKey: "salary",
        search: true,
        height: "auto",
        placeholder: "Select Salary Range",
        customComparator: () => {},
        moreText: "more",
        noResultsFound: "No results found!",
        searchPlaceholder: "Search",
        searchOnKey: "salary",
        clearOnSelection: false,
        inputDirection: "ltr",
    };
}
