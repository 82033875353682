import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { JobTitleService } from "src/app/shared/dummy-data/job-create.service";
import { ApplyJobService } from "src/app/shared/services/apply-job.service";
import { AuthService } from "src/app/store/auth/auth.service";

@Component({
    selector: "app-home-one",
    templateUrl: "./home-one.component.html",
    styleUrls: ["./home-one.component.scss"],
})
export class HomeOneComponent implements OnInit {
    category: string = "";
    categoryArray: any[] = [];
    job: string = "";
    countries: any[] = [];
    userO = this.authService.userO;
    private userSub: Subscription;
    isAdmin: boolean = false;

    videos = [
        {
            title: "Magnus Recruitment...",
            url: "../../../../assets/img/home-1/magnus.mp4",
            description: "Weekly Job Newsletter",
        },
        {
            title: "Magnus Recruitment...",
            url: "../../../../assets/img/home-1/magnus.mp4",
            description: "Weekly Job Newsletter",
        },
        {
            title: "Magnus Recruitment...",
            url: "../../../../assets/img/home-1/magnus.mp4",
            description: "Weekly Job Newsletter",
        },
        {
            title: "Magnus Recruitment...",
            url: "../../../../assets/img/home-1/magnus.mp4",
            description: "Weekly Job Newsletter",
        },
    ];
  jobInfoListing: any[] = [];
  filteredJobs: any[] = [];
  loginUserData: any = {};
    constructor(
        private router: Router,
        private readonly jobTitleService: JobTitleService,
        private readonly applyJobService:ApplyJobService,
        private readonly authService: AuthService
    ) {}

    ngOnInit(): void {
      this.userSub = this.userO.subscribe((data) => {
        if (!data) return;
        if (data) {
            this.loginUserData = data?.body ?? data;
            this.isAdmin = this.loginUserData?.role === 'admin';
            console.log('loginDataExistttt%%%%%%%%%' , this.isAdmin)
        } 
      });
        localStorage.removeItem("questions-id");
        this.getCountries();
        this.getAllRecentJobInfo()
    }


    handleJobClick(data: any) {
      if (this.loginUserData) {
        this.router.navigate(['/job-list'] , {
          queryParams: { title: data?.jobTitle }
        });
        if(this.loginUserData?.role === 'admin'){
          this.router.navigate(['/admin/job-list'] , {
            queryParams: { title: data?.jobTitle }
          });
        }
        
      } else {
        this.router.navigate(['/management-module/login'], {
          queryParams: { title: data?.jobTitle }
        });
      }
    }

    
    getCountries() {
        this.countries = this.jobTitleService.getCountries();
    }

    getAllRecentJobInfo() {
      this.applyJobService.getAllDummyDataOfCountries().subscribe((res: any) => {
        this.jobInfoListing = res?.body || [];
        this.filteredJobs = [...this.jobInfoListing];
        console.log(this.filteredJobs)
      });
    }
  
    filterJobs(jobType: string) {
      if (jobType === 'all') {
        this.filteredJobs = [...this.jobInfoListing]; // Show all jobs
      } else {
        this.filteredJobs = this.jobInfoListing.filter(job =>
          job.jobType.toLowerCase() === jobType.toLowerCase()
        );
      }
    }

    onSelectCountry(country: any) {
      const selectedCountry = country?.value?.country;
      console.log('Selected Country:', selectedCountry);
    
      if (selectedCountry) {
        this.filteredJobs = this.jobInfoListing.filter(
          (job) => job.country?.toLowerCase() === selectedCountry.toLowerCase()
        );
      } else {
        this.filteredJobs = [...this.jobInfoListing];
      }
    }
    


    navigateToCandidateList() {
        this.router.navigate(["/candidate-list"], {
            queryParams: {
                category: this.category,
            },
        });
    }

    navigateToJobList() {
        this.router.navigate(["/job-list"], {
            queryParams: {
                job: this.job,
            },
        });

        // const jobArray = [this.job];
        // this.router.navigate(['/job-list'], {
        //     queryParams: {
        //         job: JSON.stringify(jobArray) // Pass as JSON string
        //     }
        // });
    }

    //ngx-selector-dropDown-Configuration for job category
    config = {
        displayKey: "country",
        search: true,
        height: "auto",
        placeholder: "others",
        customComparator: () => {},
        moreText: "more",
        noResultsFound: "No results found!",
        searchPlaceholder: "Search",
        searchOnKey: "country",
        clearOnSelection: false,
        inputDirection: "ltr"
    };


    ngOnDestroy() {
      this.userSub.unsubscribe();
    }
}
