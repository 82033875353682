import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { AuthService } from 'src/app/store/auth/auth.service';
declare var Calendly: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  loginAuthO = this.authService.userO;
  registerUser = this.authService.userO;
  private loginSub: Subscription;
  private registerSub: Subscription;
  isAdmin: boolean = false;
  userInfo: any = {};

  currentYear: number;

       
        constructor(
          private readonly authService: AuthService,
        private localStorageService: LocalStorageService
        ) { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }


  ngAfterViewInit() {
    setTimeout(() => {
        this.loginSub = this.loginAuthO.subscribe((data) => {
            if (!data) return;
            const userData = this.localStorageService.getUserData();
            if (userData && (Object.keys(userData).length > 0) && data?.isData === true || (data && data?.body !== null)) {
                this.userInfo = data?.body ?? data;
                this.isAdmin = this.userInfo?.role === 'admin';
                console.log('user ka data' , this.isAdmin)
            }
        });
    }, 0);
  }


  openCalendly(): void {
    // Use Calendly's popup widget to open the scheduling interface
    Calendly.initPopupWidget({
        url: "https://calendly.com/accounts-magnusrecruitment/15min", // Replace with your scheduling link
    });
}


ngOnDestroy() {
  if (this.loginSub) {
      this.loginSub.unsubscribe();
  }
  if (this.registerSub) {
      this.registerSub.unsubscribe();
  }
}

}
